// this component is for the vessel score ui part, consisting of ship and bar charts and color logic
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';

@Component({
  selector: 'champs-evaluation-vessel-score',
  templateUrl: './evaluation-vessel-score.component.html',
  styleUrls: ['./evaluation-vessel-score.component.scss'],
})
export class EvaluationVesselScoreComponent implements OnInit, OnChanges {
  @Input() public imoNumber: number;
  @Input() public vslFRPScore: any;
  public id: any;
  public barchart: any;

  public vesselScore: any;
  public vesselOutstandingsUnit: any;
  public vesselOutstandingsValue: any;
  // public showSapAlert: boolean;

  /** colors */
  public grey = '#edf0f2';
  public tomato = '#d62d23';
  public mango = '#ff9b1e';
  /** red */
  public darkRed = '#C74E46';
  public normalRed = '#DE574F';
  public lightRed = '#E27069';
  public lighterRed = '#E78984';
  /** orange */
  public darkOrange = '#E59534';
  public normalOrange = '#FFA73B ';
  public lightOrange = '#FFB458';
  public lighterOrange = '#FFC176';
  /** green */
  public darkGreen = '#2dba5e';
  public normalGreen = '#55c77d';
  public lightGreen = '#5ad585';
  public lighterGreen = '#a3dcaf';

  /** for color configuration */
  public colorConfigList: any[] = [];
  public keyValues: any[] = [];
  public valuesInStringForm: any[] = [];
  public values: any[] = [];
  public namesValuesInStringForm: any[] = [];
  public namesValues: any[] = [];
  public names: any[] = [];
  public lowValue: any;
  public mediumValue: any;
  public highValue: any;
  public lowValueScore: any;
  public mediumValueScore: any;
  public highValueScore: any;
  public lowValueColor: any;
  public mediumValueColor: any;
  public highValueColor: any;

  constructor(
    private route: ActivatedRoute,
    private vesselsService: VesselsService,
    private colorConfigService: ColorConfigService
  ) {
    this.id = this.route.parent ? this.route.parent.snapshot.paramMap.get('id') : 0;
    this.vesselScore = 0.0;
  }

  public ngOnChanges() {}

  public ngOnInit() {
    /** integrated mode (comment this for hardcoded mode) */
    const service1 = this.vesselsService.getVesselScore(this.id);
    const service2 = this.vesselsService.getVesselScoreOutstandings(this.id);
    forkJoin(service1, service2).subscribe((data) => {
      this.vesselScore = data;
      this.vesselOutstandingsValue = data[1];
      this.vesselOutstandingsUnit = 'USD';

      this.colorConfigService.getColorConfigByGroupName('Scores - Vessel').subscribe((colorConfigList: any) => {
        this.colorConfigList = colorConfigList;
        this.keyValues = this.colorConfigList[0].keyValues;
        this.getBarChart();
      });
    });
  }

  public getBarChart() {
    this.barchart = new Chart({
      chart: {
        type: 'column',
        width: 647,
        height: 101,
        events: {
          load: function () {
            /** here image of ship is rendered, with (uri,x,y,width,height) parameters */
            this.renderer
              .image('assets/images/Ship_white.svg', 0, 0, 647, 101)
              .attr({
                zIndex: 4,
              })
              .add();
          },
        },
      },
      title: { text: null },
      tooltip: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        tickLength: 0,
        offset: 23,
        lineColor: null,
        labels: { enabled: false },
      },
      yAxis: { visible: false },
      legend: { enabled: false },
      plotOptions: {
        column: {
          pointWidth: 33,
          borderWidth: 0,
          pointPadding: -0.6,
          colorByPoint: true,
        },
        series: {
          states: {
            hover: { enabled: false },
          },
        },
      },
      /** colors always takes an array of colors, which contains color of each column */
      colors: this.getBarChartColor(),
      /** this is to give the heights of the column charts */
      series: [
        {
          data: [12, 12, 12, 10, 12, 12, 0, 0, 10, 12, 12, 12, 12, 12, 12, 12, 12, 10],
        },
      ],
    });
  }

  /** here the color coding logic is implemented */
  public getBarChartColor() {
    let barsToBeColored = 0;
    const colors = new Array(18).fill(this.grey); // initialising all bar charts to be grey
    if (this.vesselScore) {
      barsToBeColored = Math.round((16 * this.vesselScore) / 5);

      this.namesValuesInStringForm = this.keyValues.map((i) => {
        if (i.kvValue === 'default') {
          return {
            kvName: i.kvName,
            kvValue: '0',
          };
        } else {
          return {
            kvName: i.kvName,
            kvValue: i.kvValue,
          };
        }
      });

      this.namesValues = this.namesValuesInStringForm.map((i) => {
        return {
          kvName: i.kvName,
          kvValue: +i.kvValue,
        };
      });

      this.namesValues.sort((a, b) => a.kvValue - b.kvValue);
      this.namesValues.forEach((i) => {
        this.names.push(i.kvName);
        this.values.push(i.kvValue);
      });

      // higest value is 5
      this.highValueScore = this.values[2];
      this.mediumValueScore = this.values[1];
      this.lowValueScore = this.values[0];

      this.highValueColor = this.names[2].substring(this.names[2].indexOf('.') + 1);
      this.mediumValueColor = this.names[1].substring(this.names[1].indexOf('.') + 1);
      this.lowValueColor = this.names[0].substring(this.names[0].indexOf('.') + 1);

      this.highValue = Math.round((16 * this.highValueScore) / 5);
      this.mediumValue = Math.round((16 * this.mediumValueScore) / 5);
      this.lowValue = Math.round((16 * this.lowValueScore) / 5);
    }

    for (let i = 0; i < 16; i++) {
      if (i < barsToBeColored) {
        if (this.vesselScore >= this.lowValueScore && this.vesselScore <= this.mediumValueScore) {
          i < 4
            ? (colors[i] = this['dark' + this['lowValueColor']])
            : i < 8
            ? (colors[i] = this['normal' + this['lowValueColor']])
            : i < 12
            ? (colors[i] = this['light' + this['lowValueColor']])
            : (colors[i] = this['lighter' + this['lowValueColor']]);
        } else if (this.vesselScore > this.mediumValueScore && this.vesselScore <= this.highValueScore) {
          i < 4
            ? (colors[i] = this['dark' + this['mediumValueColor']])
            : i < 8
            ? (colors[i] = this['normal' + this['mediumValueColor']])
            : i < 12
            ? (colors[i] = this['light' + this['mediumValueColor']])
            : (colors[i] = this['lighter' + this['mediumValueColor']]);
        } else if (this.vesselScore > this.highValueScore && this.vesselScore <= 5 && barsToBeColored <= 12) {
          i < 4
            ? (colors[i] = this['dark' + this['highValueColor']])
            : i < 8
            ? (colors[i] = this['normal' + this['highValueColor']])
            : i < 12
            ? (colors[i] = this['light' + this['highValueColor']])
            : (colors[i] = this['lighter' + this['highValueColor']]);
        } else if (this.vesselScore > this.highValueScore && this.vesselScore <= 5 && barsToBeColored > 12) {
          i < 4
            ? (colors[i] = this['dark' + this['highValueColor']])
            : i < 8
            ? (colors[i] = this['normal' + this['highValueColor']])
            : i < 12
            ? (colors[i] = this['light' + this['highValueColor']])
            : (colors[i] = this['lighter' + this['highValueColor']]);
        }
      }
    }
    colors.splice(6, 0, 'null', 'null'); // giving null color values to the 2 hidden columns in 6,7 positions
    return colors;
  }

  /** */
  public navigateToFixOverview(fixId) {
    window.open('/fixtures/' + fixId);
  }
}

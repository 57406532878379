//

export const environment = {
  production: Boolean('true'),
  baseUrl: 'https://champs.maersk.com',
  uiUrl: 'https://champs.maersk.com',
  postLogoutUrl: 'https://champs.maersk.com',
  appInsights: {
    instrumentationKey: 'f3513126-b284-4322-ae84-57dce4f5dae7'
  },
  schedulePlannerUrl: 'https://comst.apmoller.net/ddnd/schedulePlanner',
  tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
  clientId: 'add73bf7-24df-4c94-8cc4-2229f2c8711c',
  MSALScope: 'https://maersk.onmicrosoft.com/14586e30-1a8b-4010-87a0-bacab2b1e090/user_impersonation',
  byPassAuthentication: 'false',
  mopName: 'champs-prod',
  mopEnvironment: 'prod',
  mopApiKey: 'R1KgIS7Pvlsp6cffPERIaT1qicwsZVw7bW+rIH+7ey1OfgEsEC2uq0UpLJPw3g=='
};

// reusable donut chart component, mainly used in vessel score evaluation screen
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';

import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';

@Component({
  selector: 'champs-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit, OnChanges {

  @Input() public title;
  @Input() public width;
  @Input() public height;
  @Input() public dadu;
  @Input() public chartData: number; // data for inner donut chart
  @Input() public chartData2: number; // data for outer donut chart
  @Input() public chartDataTextMin: any; // label text (minimum) for inner donut chart
  @Input() public chartDataTextMax: any; // label text (maximum) for inner donut chart
  @Input() public chartData2TextMin: any; // label text (minimum) for outer donut chart
  @Input() public colorConfigList: any; // for color coding

  public chart: any;

  /** colors */
  public grey = '#edf0f2';
  public tomato = '#d62d23';
  public mango = '#ff9b1e';
  /** red */
  public darkRed = '#C74E46';
  public normalRed = '#DE574F';
  public lightRed = '#E27069';
  public lighterRed = '#E78984';
  /** orange */
  public darkOrange = '#E59534';
  public normalOrange = '#FFA73B ';
  public lightOrange = '#FFB458';
  public lighterOrange = '#FFC176';
  /** green */
  public darkGreen = '#2dba5e';
  public normalGreen = '#55c77d';
  public lightGreen = '#5ad585';
  public lighterGreen = '#a3dcaf';

  public red = '#d62d23';
  public orange = '#ff9b1e';
  public green = '#2dba5e';
  public transparentColor = '#dadada';

  /** for color configuration */
  public keyValues: any[] = [];
  public valuesInStringForm: any[] = [];
  public values: any[] = [];
  public namesValuesInStringForm: any[] = [];
  public namesValues: any[] = [];
  public names: any[] = [];
  public lowValue: any;
  public mediumValue: any;
  public highValue: any;
  public lowValueScore: any;
  public mediumValueScore: any;
  public highValueScore: any;
  public lowValueColor: any;
  public mediumValueColor: any;
  public highValueColor: any;

  constructor(private colorConfigService: ColorConfigService) { }

  public ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData && changes.chartData.currentValue != null && changes.colorConfigList && changes.colorConfigList.currentValue) {
      this.keyValues = this.colorConfigList[0].keyValues;
      this.getChartsData();
    }
  }

  public getChartsData() {
    const __this = this;

    this.namesValuesInStringForm = this.keyValues.map(i => {
      if (i.kvValue === 'default') {
        return {
          'kvName': i.kvName,
          'kvValue': '0'
        };
      } else {
        return {
          'kvName': i.kvName,
          'kvValue': i.kvValue
        };
      }
    });

    this.namesValues = this.namesValuesInStringForm.map(i => {
      return {
        'kvName': i.kvName,
        'kvValue': +i.kvValue
      };
    });

    this.namesValues.sort((a, b) => a.kvValue - b.kvValue);
    this.namesValues.forEach(i => {
      this.names.push(i.kvName);
      this.values.push(i.kvValue);
    }
    );

    // higest value is 5
    this.highValueScore = this.values[2];
    this.mediumValueScore = this.values[1];
    this.lowValueScore = this.values[0];

    this.highValueColor = this.names[2].substring(this.names[2].indexOf('.') + 1).toLowerCase();
    this.mediumValueColor = this.names[1].substring(this.names[1].indexOf('.') + 1).toLowerCase();
    this.lowValueColor = this.names[0].substring(this.names[0].indexOf('.') + 1).toLowerCase();

    /** here the chart is being generated */
    this.chart = new Chart({
      chart: {
        type: 'pie',
        width: this.width,
        height: this.height,
        margin: 0,
        style: {
          padding: '0px 0px 0px 11px'
        },
        events: {
          /** this is to render the other labels (other than the title, i.e. score and chart title) and their x and y coordinates depending on the chart */
          load: function (event) {
            this.renderer.label('<div style="color: #888888; font-family: MaerskText;">' + __this.chartDataTextMin + '</div>', 46, 113, null, null, null, true).add();
            this.renderer.label('<div style="color: #888888; font-family: MaerskText;">' + __this.chartDataTextMax + '</div>', 165, 113, null, null, null, true).add();
            this.renderer.label('<div style="color: #888888; font-family: MaerskText;">' + __this.chartData2TextMin + '</div>', 7, 100, null, null, null, true).add();
          }
        }
      },
      title: {
        /** this is for the title, inline style has to be added to override other styles */
        text: `<div style="font-size: 22px !important; font-family: MaerskText;">${this.chartDataTextMin !== 'Crane' ? this.chartData.toString() : 'N/A'}</div><br><div style="color: #888888; font-family: MaerskText;">${this.title}</div>`,
        y: 85,

      },

      tooltip: {

        pointFormat: '{series.name}: <strong>{point.percentage:.2f}%</strong> '
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          }
        },
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [
        {
          name: this.chartData2TextMin,
          index: 0,
          innerSize: '92%',
          size: '100%',
          data: this.getChartData2(),
          visible: this.chartData2 != null
        },
        {
          name: this.chartDataTextMin,
          index: 1,
          size: '85%',
          innerSize: '50%',
          data: this.getChartData()
        }
      ]
    });
  }

  /** this is just for debugging purposes */
  public changeChart() {
    this.chartData = Math.floor(0.9698949485766697 * 101);
    this.chart.ref.update({
      title: { text: this.chartData ? this.chartData.toString() : null }
    });
    this.chart.ref.series[1].setData(this.getChartData());
  }

  /** these two functions are for initialising the grey colors on the chart at the beginning */

  /** inner donut */
  public getChartData() {
    const data: {
      id: string,
      y: number,
      color: string,
      high?: number
    }[] = [
        {
          id: 'data',
          y: this.chartData,
          color: this.getChartDataColor(this.chartData)
        },
        {
          id: 'p1',
          y: this.mediumValueScore > this.chartData
            ? this.mediumValueScore - this.chartData
            : 0,
          color: '#dadada'
        },
        {
          id: 'p2',
          y: this.highValueScore > this.chartData
            ? this.mediumValueScore > this.chartData
              ? this.highValueScore - this.mediumValueScore
              : this.highValueScore - this.chartData
            : 0,
          color: '#e6e6e6'
        },
        {
          id: 'p3',
          y: 100 > this.chartData
            ? this.highValueScore > this.chartData
              ? 100 - this.highValueScore
              : 100 - this.chartData
            : 0,
          color: '#edf0f2'
        }
      ];
    return data;
  }

  /** upper donut */
  public getChartData2() {
    const data: {
      id: string,
      y: number,
      color: string,
      high?: number
    }[] = [
        {
          id: 'data',
          y: this.chartData2,
          color: this.getChartDataColor(this.chartData2)
        },
        {
          id: 'p1',
          y: this.mediumValueScore > this.chartData2
            ? this.mediumValueScore - this.chartData2
            : 0,
          color: '#dadada'
        },
        {
          id: 'p2',
          y: this.highValueScore > this.chartData2
            ? this.mediumValueScore > this.chartData2
              ? this.highValueScore - this.mediumValueScore
              : this.highValueScore - this.chartData2
            : 0,
          color: '#e6e6e6'
        },
        {
          id: 'p3',
          y: 100 > this.chartData2
            ? this.highValueScore > this.chartData2
              ? 100 - this.highValueScore
              : 100 - this.chartData2
            : 0,
          color: '#edf0f2'
        }
      ];
    return data;
  }

  /** this is to give the donut chart the necessary color depending on score */
  public getChartDataColor(chartData) {
    if (chartData >= this.lowValueScore && chartData <= this.mediumValueScore) {
      return this[this.lowValueColor];
    } else if (chartData > this.mediumValueScore && chartData <= this.highValueScore) {
      return this[this.mediumValueColor];
    } else if (chartData > this.highValueScore) {
      return this[this.highValueColor];
    } else {
      return this.transparentColor; // transparent
    }
  }

}

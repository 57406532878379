//

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartModule } from 'angular-highcharts';
import { ColorPickerModule } from 'ngx-color-picker';

import { VesselListComponent } from './vessel-list/vessel-list.component';
import { VesselDetailComponent } from './vessel-detail/vessel-detail.component';
import { ComponentsModule } from '../components/components.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { VesselNewComponent } from './vessel-new/vessel-new.component';
import { AlertNotificationModule } from '../modules/alert-notification/alert-notification.module';
import { VesselImportComponent } from './vessel-import/vessel-import.component';
import { VesselDetailOverviewComponent } from './vessel-detail/overview/overview.component';
import { VesselDetailDetailsComponent } from './vessel-detail/details/details.component';
import { VesselDetailEvaluationComponent } from './vessel-detail/evaluation/evaluation.component';
import { VesselDetailsResolver } from './vessel-detail/vessel-detail.resolver';
import { CanDeactivateModule } from '../modules/can-deactivate/can-deactivate.module';
import { MaterialModule } from '../material/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HomePageComponent } from './home-page/home-page.component';
import { A3ViewComponent } from './a3-view/a3-view.component';
import {ServiceViewComponent } from './service-view/service-view.component';
import { StandaloneReportAppComponent } from './standalone-report-app/standalone-report-app.component';
import { StandaloneReportDashboardsComponent } from './standalone-report-app/dashboards/standalone-report-dashboards';
import { DashboardReports } from './standalone-report-app/dashboard-reports/dashboard-reports';
import { EvaluationVesselScoreComponent } from './vessel-detail/evaluation/evaluation-vessel-score/evaluation-vessel-score.component';
import { EvaluationScoreChartsComponent } from './vessel-detail/evaluation/evaluation-score-charts/evaluation-score-charts.component';
import { DateAdapter } from '@angular/material/core';
import { TempCustomMomentUtcAdapter } from './../components/custom-moment-datepicker/temp-custom-moment-utc-adapter';
import { ClickOutsideModule } from 'ng-click-outside';
import { VeselNewDummyComponent } from './vesel-new-dummy/vesel-new-dummy.component';
import { CommonPlannerModule } from '../modules/common-planner/common-planner.module';
import { PipeModule } from '../pipe/pipe.module';
import { FeatureToggleComponent } from './feature-toggle/feature-toggle.component';
import { MatTableModule } from '@angular/material/table';
import { A3FiltersComponent } from './a3-view/a3-filters/a3-filters.component';
import { A3VesselDetailComponent } from './a3-view/a3-vessel-detail/a3-vessel-detail.component';
import { HealthComponent } from './health/health.component';
import { MatMenuModule } from '@angular/material/menu';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerbiReportComponent } from './standalone-report-app/powerbi-report/powerbi-report/powerbi-report.component';
import { ServiceViewFiltersComponent } from './service-view/service-view-filters/service-view-filters.component';
import { ServiceViewDetailComponent } from './service-view/service-view-detail/service-view-detail.component';
import { PowerbiReportListComponent } from './standalone-report-app/powerbi-report/powerbi-report-list/powerbi-report-list.component';
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgxDatatableModule,
    ColorPickerModule,
    RouterModule,
    ComponentsModule,
    AngularSvgIconModule.forRoot(),
    CanDeactivateModule,
    DirectivesModule,
    AlertNotificationModule,
    ChartModule,
    ClickOutsideModule,
    CommonPlannerModule,
    PipeModule,
    MatTableModule,
    FormsModule,
    NgSelectModule,
    MatMenuModule,
    PowerBIEmbedModule
  ],
  declarations: [
    VesselListComponent,
    VesselDetailComponent,
    VesselNewComponent,
    VesselImportComponent,
    VesselDetailOverviewComponent,
    VesselDetailDetailsComponent,
    VesselDetailEvaluationComponent,
    HomePageComponent,
    EvaluationVesselScoreComponent,
    EvaluationScoreChartsComponent,
    VeselNewDummyComponent,
    StandaloneReportAppComponent,
    StandaloneReportDashboardsComponent,
    A3ViewComponent,
    FeatureToggleComponent,
    A3FiltersComponent,
    DashboardReports,
    A3VesselDetailComponent,
    HealthComponent,
    ServiceViewComponent,
    PowerbiReportComponent,
    ServiceViewFiltersComponent,
    ServiceViewDetailComponent,
    PowerbiReportListComponent,
  ],
  providers: [VesselDetailsResolver, { provide: DateAdapter, useClass: TempCustomMomentUtcAdapter }],
})
export class PagesModule {}
